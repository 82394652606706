import React, { useState } from "react";
import { FaUser, FaEnvelope, FaPhone, FaBuilding } from "react-icons/fa";
import { createConsultation } from "../services/apiServices"; // Import the API action
import { toast } from "react-toastify"; // Assuming you are using react-toastify for notifications

const countries = [
  "North America",
  "Europe",
  "Asia",
  "Africa",
  "Australia",
  "South America",
  "Antarctica",
  "Pakistan",
  "United States",
  "United Kingdom",
  "Canada",
  "Germany",
  "France",
  "China",
  "India",
  "Japan",
  "Brazil",
  "Mexico",
  "South Africa",
  "Australia",
];

const ContactSection = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    region: "",
    projectDetail: "",
  });
  const [loading, setLoading] = useState(false); // Loading state

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.region ||
      !formData.projectDetail
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    try {
      // Call the create consultation API
      await createConsultation(formData);
      toast.success("Consultation submitted successfully!");
      // Reset form data
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        region: "",
        projectDetail: "",
      });
    } catch (error) {
      toast.error("Failed to submit consultation. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section
      id="contact"
      className="py-20 bg-gradient-to-r from-blue-50 via-white to-purple-50 relative overflow-hidden"
    >
      {/* Toast container */}
      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-64 h-64 bg-gradient-to-br from-blue-300 to-purple-400 opacity-30 rounded-full blur-3xl"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-gradient-to-br from-blue-300 to-purple-400 opacity-30 rounded-full blur-3xl"></div>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 relative z-10">
        {/* Left Column - Heading and Description */}
        <div className="flex flex-col justify-center">
          <h2 className="text-5xl font-bold text-gray-900 mb-4">
            Let's discuss your project
          </h2>
          <p className="text-lg text-gray-700 mb-6">
            We are committed to understanding your requirements and crafting a
            tailored solution that aligns with your goals.
          </p>
          <p className="text-lg text-gray-700">
            Enter your details, and someone from our team will reach out to find
            a time to connect with you.
          </p>
        </div>

        {/* Right Column - Form */}
        <div>
          <form
            onSubmit={handleSubmit}
            className="space-y-6 bg-white p-10 rounded-xl shadow-2xl"
          >
            {/* First Name and Last Name */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="relative">
                <FaUser className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full pl-12 p-4 rounded-lg bg-gray-50 border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200"
                  placeholder="First Name*"
                />
              </div>
              <div className="relative">
                <FaUser className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full pl-12 p-4 rounded-lg bg-gray-50 border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200"
                  placeholder="Last Name*"
                />
              </div>
            </div>
            {/* Email */}
            <div className="relative">
              <FaEnvelope className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full pl-12 p-4 rounded-lg bg-gray-50 border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200"
                placeholder="Email*"
              />
            </div>
            {/* Phone Number */}
            <div className="relative">
              <FaPhone className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="w-full pl-12 p-4 rounded-lg bg-gray-50 border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200"
                placeholder="Phone Number*"
              />
            </div>
            {/* Company Name */}
            <div className="relative">
              <FaBuilding className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                className="w-full pl-12 p-4 rounded-lg bg-gray-50 border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200"
                placeholder="Company Name"
              />
            </div>
            {/* Region (Select) */}
            <select
              name="region"
              value={formData.region}
              onChange={handleChange}
              className="w-full p-4 rounded-lg bg-gray-50 border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200"
            >
              <option value="">Select Your Region*</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
            {/* Project Details */}
            <textarea
              name="projectDetail"
              value={formData.projectDetail}
              onChange={handleChange}
              className="w-full p-4 rounded-lg bg-gray-50 border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200"
              rows="5"
              placeholder="Project Details*"
            ></textarea>
            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-3 rounded-full hover:from-indigo-600 hover:to-purple-700 transition duration-300"
              disabled={loading} // Disable button while loading
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
