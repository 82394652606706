import { FaCode, FaMobileAlt, FaCloud } from "react-icons/fa"; // Icons from react-icons

const services = [
  { title: "Custom Software Development", icon: <FaCode /> },
  { title: "Mobile App Development", icon: <FaMobileAlt /> },
  { title: "Cloud Solutions", icon: <FaCloud /> },
];

const ServicesSection = () => {
  return (
    <section
      id="services"
      className="py-20 bg-gradient-to-b from-white via-blue-50 to-indigo-100 relative overflow-hidden"
    >
      {/* Background decorative elements */}
      <div className="absolute top-0 left-0 w-64 h-64 bg-gradient-to-br from-purple-400 to-indigo-600 rounded-full opacity-30 blur-3xl transform translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-gradient-to-br from-blue-400 to-teal-600 rounded-full opacity-30 blur-3xl transform -translate-x-1/2 translate-y-1/2"></div>

      <div className="relative max-w-7xl mx-auto text-center">
        {/* Section Heading */}
        <h2 className="text-5xl font-bold text-gray-900">
          Our <span className="text-indigo-600">Services</span>
        </h2>
        <p className="text-lg text-gray-600 mt-4 max-w-2xl mx-auto">
          We provide cutting-edge solutions tailored to your business needs,
          ensuring your growth in the digital era.
        </p>

        {/* Services Cards */}
        <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-12">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white p-8 rounded-2xl shadow-lg transform transition duration-300 hover:scale-105 hover:rotate-1 hover:shadow-2xl"
            >
              <div className="flex justify-center items-center bg-gradient-to-r from-indigo-500 to-purple-500 text-white p-6 rounded-full mb-6 w-20 h-20 shadow-lg transform transition hover:scale-110">
                <span className="text-4xl">{service.icon}</span>
              </div>
              <h3 className="text-2xl font-bold text-gray-800">
                {service.title}
              </h3>
              <p className="mt-4 text-gray-500">
                We offer unparalleled expertise in {service.title}, leveraging
                modern tools and technologies to deliver exceptional results.
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
