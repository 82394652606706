import React from "react";

const HeroSection = () => {
  return (
    <section
      id="hero"
      className=" flex flex-col items-center justify-center bg-gradient-to-r from-indigo-300 to-blue-300 relative z-10 pt-20"
    >
      {/* Main Content */}
      <div className="text-center max-w-2xl mx-auto px-4">
        {/* Main Heading */}
        <h1 className="text-5xl md:text-6xl font-extrabold text-gray-800 leading-tight tracking-tight">
          We help you{" "}
          <span className="relative inline-block">
            <span className="relative z-10 text-indigo-900">lead</span>
            {/* Curved Underline Effect */}
            <span className="absolute bottom-1 left-0 w-full h-3 bg-yellow-300 rounded-full opacity-90 transform rotate-1"></span>
          </span>{" "}
          with great software
        </h1>

        {/* Subheading */}
        <p className="text-lg md:text-xl text-gray-600 mt-6 tracking-wide">
          Our team of 2,000+ skilled software professionals can build the right
          solution for you. Let’s create something that helps your business
          grow.
        </p>

        {/* Call to Action Buttons */}
        <div className="mt-10 flex flex-col md:flex-row justify-center items-center gap-6">
          <a
            href="#portfolio"
            className="px-8 py-4 bg-white border-2 border-gray-800 text-gray-800 rounded-md font-semibold shadow-xl hover:bg-gray-50 transition transform duration-300 hover:scale-105 pointer-events-auto"
          >
            View Our Work
          </a>
          <a
            href="#contact"
            className="px-8 py-4 bg-blue-600 text-white rounded-md font-semibold shadow-xl hover:bg-blue-700 transition transform duration-300 hover:scale-105 pointer-events-auto"
          >
            Book a Free Consultation
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
