import React, { useState, useEffect, useRef } from "react";
import ButtonLoader from "./loaders/ButtonLoader"; // Assuming the loader is in this path
import { getIntroductionVideo } from "../services/apiServices"; // Assuming the API action is in this path

const IntroductionVideo = () => {
  const [aspectRatio, setAspectRatio] = useState(16 / 9); // Default to 16:9 aspect ratio
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(true); // For showing the loader
  const videoRef = useRef(null);

  // Fetch introduction video on component mount
  useEffect(() => {
    const fetchVideo = async () => {
      setLoading(true);
      try {
        const response = await getIntroductionVideo();
        if (response.status === "success") {
          setVideoUrl(response.data.introductionVideo);
        }
      } catch (error) {
        console.error("Failed to fetch video:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, []);

  // Adjust aspect ratio based on the actual video size
  useEffect(() => {
    const video = videoRef.current;
    const handleLoadedMetadata = () => {
      const { videoWidth, videoHeight } = video;
      setAspectRatio(videoWidth / videoHeight);
    };

    if (video) {
      video.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () => {
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      };
    }
  }, [videoUrl]);

  return (
    <section className="flex justify-center items-center bg-gray-900 text-white h-[391px]">
      {loading ? (
        <ButtonLoader /> /* Show loader during loading */
      ) : (
        <div className="w-full" style={{ maxWidth: `${aspectRatio * 100}vw` }}>
          <div
            className="relative"
            style={{
              paddingBottom: `${(1 / aspectRatio) * 100}%`, // Set height based on aspect ratio
              position: "relative",
            }}
          >
            <video
              ref={videoRef}
              className="absolute top-0 left-0 w-full rounded-lg shadow-lg"
              controls
              src={videoUrl}
              title="MantiqSoft Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default IntroductionVideo;
