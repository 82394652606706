import { useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <header className="bg-gray-900 text-white shadow-lg fixed w-full top-0 z-50">
      <nav className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <img src="../logo192.png" alt="logo" className="w-10" />
          <div className="text-2xl font-bold">MantiqSoft</div>
        </div>
        <ul className="hidden md:flex space-x-8">
          <li>
            <a href="#hero" className="hover:text-indigo-400">
              Home
            </a>
          </li>
          <li>
            <a href="#about" className="hover:text-indigo-400">
              About
            </a>
          </li>
          <li>
            <a href="#services" className="hover:text-indigo-400">
              Services
            </a>
          </li>
          <li>
            <a href="#portfolio" className="hover:text-indigo-400">
              Portfolio
            </a>
          </li>
          <li>
            <a href="#contact" className="hover:text-indigo-400">
              Contact
            </a>
          </li>
        </ul>

        {/* Mobile Menu Icon */}
        <div className="md:hidden ml-auto mr-9">
          <button
            onClick={toggleDrawer}
            className="text-white focus:outline-none"
          >
            <FaBars className="h-6 w-6" />
          </button>
        </div>

        {/* Drawer for Mobile */}
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="bg-gray-200 z-50"
        >
          <div className="p-4 flex justify-between">
            <div className="flex p-4">
              <img src="../logo192.png" alt="logo" className="w-10" />
            </div>
            <button
              onClick={toggleDrawer}
              className="text-black focus:outline-none"
            >
              <FaTimes className="h-6 w-6" />
            </button>
          </div>
          <ul className="space-y-6 p-6">
            <li>
              <a
                href="#hero"
                className="text-black hover:text-indigo-400 block"
                onClick={toggleDrawer}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="text-black hover:text-indigo-400 block"
                onClick={toggleDrawer}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="text-black hover:text-indigo-400 block"
                onClick={toggleDrawer}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#portfolio"
                className="text-black hover:text-indigo-400 block"
                onClick={toggleDrawer}
              >
                Portfolio
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="text-black hover:text-indigo-400 block"
                onClick={toggleDrawer}
              >
                Contact
              </a>
            </li>
          </ul>
        </Drawer>
      </nav>
    </header>
  );
};

export default Header;
