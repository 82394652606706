import { FaCogs, FaUserFriends, FaRocket } from "react-icons/fa"; // Icons from react-icons

const AboutSection = () => {
  return (
    <section
      id="about"
      className="py-20 bg-gradient-to-r from-blue-50 to-indigo-100 relative"
    >
      {/* Decorative Background Elements */}
      <div className="absolute top-0 left-0 w-64 h-64 bg-blue-200 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-pulse"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-purple-200 rounded-full mix-blend-multiply opacity-30 filter blur-3xl animate-pulse"></div>

      {/* Content Container */}
      <div className="relative max-w-6xl mx-auto text-center">
        <h2 className="text-5xl font-extrabold text-gray-800">About Us</h2>
        <p className="text-lg md:text-xl text-gray-600 mt-4 max-w-3xl mx-auto">
          At MantiqSoft, we specialize in delivering world-class software
          solutions tailored to meet your business needs. Our team of experts is
          dedicated to transforming your ideas into reality with innovative
          technology.
        </p>

        {/* Icon Section */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="flex flex-col items-center">
            <FaCogs className="text-indigo-600 text-6xl mb-4" />
            <h3 className="text-2xl font-bold text-gray-800">Innovation</h3>
            <p className="text-gray-600 mt-2">
              We harness the latest technology to deliver innovative solutions
              for your business.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <FaUserFriends className="text-indigo-600 text-6xl mb-4" />
            <h3 className="text-2xl font-bold text-gray-800">Expert Team</h3>
            <p className="text-gray-600 mt-2">
              Our experienced professionals are dedicated to turning your vision
              into reality.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <FaRocket className="text-indigo-600 text-6xl mb-4" />
            <h3 className="text-2xl font-bold text-gray-800">Growth</h3>
            <p className="text-gray-600 mt-2">
              We help your business grow and scale with our software solutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
