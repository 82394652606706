import React, { useEffect, useState } from "react";
import { FaQuoteLeft } from "react-icons/fa"; // Importing an icon for quotes
import ButtonLoader from "./loaders/ButtonLoader"; // Assuming you have a loader component
import { getReviews } from "../services/apiServices"; // Import the API service for fetching reviews

const TestimonialsSection = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch reviews from the API on component mount
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await getReviews();
        if (response.status === "success") {
          setReviews(response.data || []); // Populate the reviews array with the fetched data
        }
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
      } finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };

    fetchReviews();
  }, []);

  return (
    <section
      id="testimonials"
      className="py-20 bg-gradient-to-r from-blue-50 via-white to-indigo-50"
    >
      <div className="max-w-7xl mx-auto text-center">
        {/* Section Heading */}
        <h2 className="text-5xl font-extrabold text-gray-900 mb-12">
          What Our Clients Say
        </h2>

        {/* Loader while fetching reviews */}
        {loading ? (
          <div className="flex justify-center items-center mt-10">
            <ButtonLoader /> {/* Display the loader */}
          </div>
        ) : (
          /* Testimonials Grid */
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {reviews.map((review, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-2xl shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl"
              >
                <FaQuoteLeft className="text-indigo-500 text-4xl mb-4" />
                <p className="text-lg italic text-gray-700 mb-6">
                  "{review.review}"
                </p>
                <h4 className="text-xl font-bold text-gray-900">
                  {review.personName}
                </h4>
                <p className="text-gray-600">{review.influence}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-64 h-64 bg-gradient-to-br from-purple-300 to-indigo-400 rounded-full opacity-30 blur-3xl"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-gradient-to-br from-purple-300 to-indigo-400 rounded-full opacity-30 blur-3xl"></div>
    </section>
  );
};

export default TestimonialsSection;
